import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from '../../../theme/layout';
import { BlockContainer } from '../container/container.component';
import { Text, Caption } from './quote.styles';
import { BlockQuoteProps } from './quote.types';

export const BlockQuote = ({theme,text,caption,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockQuoteProps) => {
  return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.GRID} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
        <Text>{text}</Text>
        <Caption>{caption}</Caption>
      </BlockContainer>
    </ThemeProvider>
  )
};