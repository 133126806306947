import styled from 'styled-components';
import { theme } from '../../../common/fragments';
import { Color, getColor } from '../../../theme/color';
import { getSpacerAsPixels } from '../../../theme/layout';
import { ResponsiveMedia } from '../responsiveMedia';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.background.hex};
`;

export const Inner = styled.div<{ mediaWidth: number, mediaHeight: number }>`
  width: calc(100vw - 40px);
  height: calc(100vh - 200px);
  max-width: ${({mediaWidth,mediaHeight}) => `calc(${(mediaWidth / mediaHeight) * 100}vh - ${200 * (mediaWidth / mediaHeight)}px)`};
  max-height: ${({mediaWidth,mediaHeight}) => `calc(${(mediaHeight / mediaWidth) * 100}vw - ${40 * (mediaHeight / mediaWidth)}px)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Media = styled(ResponsiveMedia)`
  width: 100%;
`;

export const CloseButton = styled.svg<{ isRTL: boolean }>`
  stroke: ${({theme}) => theme.primary.hex};
  position: absolute;
  top: ${getSpacerAsPixels()};
  ${({isRTL}) => isRTL ? `left` : `right`}: ${getSpacerAsPixels()};
`