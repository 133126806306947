import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Label = styled.span`
  ${getTypography(Type.SMALL_HEADING)}
  display: flex;
  grid-column: 1 / span 12;
  align-items: center;

  &:before{
    content: "";
    display: inline-block;
    height: 80%;
    width: ${getUnitAsPixels({multiplier:8})};
    background-color: ${({theme}) => theme.primary.hex};
    margin-inline-end: ${getUnitAsPixels()};
  }

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 8;
    margin-bottom: ${getSpacerAsPixels()};
  }
`;

export const Heading = styled.h2`
  ${getTypography(Type.LARGE_HEADING)}
  grid-column: 1 / span 12;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    margin-bottom: ${getSpacerAsPixels()};
  }
`;