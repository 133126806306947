import React, { useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { getThemeByName } from '../../../theme/color';
import { Layout, Size } from '../../../theme/layout';
import { MediaButton } from '../../core/mediaButton';
import { MediaButtonIcon } from '../../core/mediaButton/mediaButton.types';
import { Overlay } from '../../core/overlay';
import { BlockContainer } from '../container/container.component';
import { Media, MediaContainer } from './mediaInternal.styles';

export const BlockMediaInternal = ({theme,media,overlay,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:{theme:{name:string},variant:string,media:any,overlay?:any,extraSpaceBefore?:boolean,extraSpaceAfter?:boolean,noSpaceBefore?:boolean,noSpaceAfter?:boolean}) => {
  const overlayRef = useRef<OverlayRefProps>(null);

  const handleMediaClick = () => {
    overlayRef.current?.show()
  }

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 12},
    [Size.LARGE]: {span: 12},
    [Size.XLARGE]: {span: 12},
    [Size.MAX]: {span: 12},
  };

  return (
    <>
      {overlay && <Overlay ref={overlayRef} {...overlay} />}
      <ThemeProvider theme={theme}>
        <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
          <MediaContainer>
            <Media defaultSource={media} sizes={sizes} onClick={handleMediaClick} />
            {overlay && <MediaButton icon={MediaButtonIcon.PLAY} onClick={handleMediaClick} />}
          </MediaContainer>
        </BlockContainer>
      </ThemeProvider>
    </>
  )
};