import React from 'react';
import { Container, Icon } from './mediaButton.styles';
import { MediaButtonProps } from './mediaButton.types';
import PlayIcon from '../../../images/play.svg';


export const MediaButton = ({icon,onClick}:MediaButtonProps) => {
  return (
    <Container onClick={onClick}>
      <Icon>
        <PlayIcon />
      </Icon>
    </Container>
  )
}