import React from 'react';
import { plainTextToHTML } from '../../../common/text';
import { ButtonLinks } from '../../core/buttonLinks';
import { SocialLinks } from '../../core/socialLinks';
import { Heading, Text } from './textContent.styles';
import { BlockTextContentProps } from './textContent.types';

export const BlockTextContent = ({heading,text,socialLinks,buttonLinks}:BlockTextContentProps) => {

  return (
    <>
      {heading && <Heading>{heading}</Heading>}
      {text && <Text>{plainTextToHTML(text)}</Text>}
      {socialLinks && socialLinks.length > 0 && <SocialLinks links={socialLinks} />}
      {buttonLinks && buttonLinks.length > 0 && <ButtonLinks links={buttonLinks} />}
    </>
  )
};