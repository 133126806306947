import styled from 'styled-components';
import { CONTAINER, getHeaderAsPixels, getLayout, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Layout, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import StarSVG from '../../../images/star.svg';

export const Container = styled.div`
  ${CONTAINER}
  background-color: ${({theme}) => theme.background.hex};
	color: ${({theme}) => theme.primary.hex};
  display: flex;
  align-items: center;
  position: relative;
  padding-top: ${getHeaderAsPixels(Size.SMALL)};
  

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getHeaderAsPixels(Size.MEDIUM)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getHeaderAsPixels(Size.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getHeaderAsPixels(Size.XLARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getHeaderAsPixels(Size.MAX)};
  }
`

export const Inner = styled.div`
  min-height: 40vw;
`

export const Content = styled.div`
  ${getLayout(Layout.GRID)}
  flex: 1;
  position: relative;
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:1})};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:1})};
  
  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE,{multiplier:1})};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE,{multiplier:1})};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE,{multiplier:1})};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE,{multiplier:1})};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE,{multiplier:1})};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE,{multiplier:1})};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE,{multiplier:1})};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE,{multiplier:1})};
  }
`

export const Heading = styled.h1`
  ${getTypography(Type.HEADLINE)}
  grid-column: 1 / span 8;
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  &:before{
    content: "";
    display: block;
    background-color: ${({theme}) => theme.primary.hex};
    height: ${getUnitAsPixels({multiplier:0.5})};
    width: ${getSpacerAsPixels({multiplier:0.5})};
    margin-bottom: ${getUnitAsPixels({multiplier:1})};
  }

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 6;
    margin-bottom: ${getSpacerAsPixels()};
  }

  ${getMediaQuery(Size.LARGE)}{
    &:before{
      height: ${getUnitAsPixels({multiplier:2})};
      width: ${getSpacerAsPixels({multiplier:2})};
      margin-bottom: ${getUnitAsPixels({multiplier:3})};
    }
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 6;
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 1 / span 4;
  }
`;

export const MediaContainer = styled.div<{rtl:boolean}>`
  grid-column: 3 / span 6;
  margin-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 5 / span 8; 
    grid-column: auto;
    margin-top: 0;
    position: absolute;
    width: 50%;
    z-index: 2;
    ${({rtl}) => rtl ? `left` : `right`}: 0;
    top: ${getHeaderAsPixels(Size.MEDIUM)};
    bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR,{multiplier:-1})};
  }

  ${getMediaQuery(Size.LARGE)}{
    top: ${getHeaderAsPixels(Size.LARGE)};
    bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR,{multiplier:-1})};
  }

  ${getMediaQuery(Size.XLARGE)}{
    top: ${getHeaderAsPixels(Size.XLARGE)};
    bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR,{multiplier:-1})};
  }

  ${getMediaQuery(Size.MAX)}{
    top: ${getHeaderAsPixels(Size.MAX)};
    bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR,{multiplier:-1})};
  }
`

export const Media = styled.img<{rtl:boolean}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  object-position: ${({rtl}) => rtl ? 'left' : 'right'} bottom;
`

export const Star = styled(StarSVG)`
  position: absolute;
  fill: ${({theme}) => theme.secondary.hex};
`

export const Stars = styled.div<{rtl:boolean}>`
  
  ${Star}{
    &:nth-child(1){
      width: ${getUnitAsPixels({multiplier:3})};
      top: ${getUnitAsPixels({multiplier:8})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:3})};
      
      ${getMediaQuery(Size.LARGE)}{
        width: ${getUnitAsPixels({multiplier:4})};
        top: ${getSpacerAsPixels({multiplier:2})};
        ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:12})};
      }
    }

    &:nth-child(2){
      width: ${getUnitAsPixels({multiplier:3})};
      bottom: ${getSpacerAsPixels({multiplier:6})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:4})};
      
      ${getMediaQuery(Size.MEDIUM)}{
        bottom: auto;
        width: ${getUnitAsPixels({multiplier:4})};
        top: ${getSpacerAsPixels({multiplier:2})};
        ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:12})};
      }
    }

    &:nth-child(3){
      width: ${getUnitAsPixels({multiplier:2})};
      top: ${getUnitAsPixels({multiplier:10})};
      ${({rtl}) => rtl ? `left` : `right`}: ${getUnitAsPixels({multiplier:8})};
      
      ${getMediaQuery(Size.LARGE)}{
        ${({rtl}) => rtl ? `left` : `right`}: ${getSpacerAsPixels({multiplier:8})};
      }
    }

    &:nth-child(4){
      width: ${getUnitAsPixels({multiplier:1})};
      top: 50%;
      ${({rtl}) => rtl ? `left` : `right`}: ${getUnitAsPixels({multiplier:2})};

      ${getMediaQuery(Size.LARGE)}{
        width: ${getUnitAsPixels({multiplier:5})};
        top: 50%;
        ${({rtl}) => rtl ? `left` : `right`}: ${getSpacerAsPixels({multiplier:10})};
      }
    }
  }
`

export const Dot = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.colors.common.light.hex};
  border-radius: 50%;
`

export const Dots = styled.div<{rtl:boolean}>`
  ${Dot}{
    &:nth-child(1){
      width: ${getUnitAsPixels({multiplier:0.5})};
      height: ${getUnitAsPixels({multiplier:0.5})};
      top: ${getSpacerAsPixels({multiplier:1})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:5})};

      ${getMediaQuery(Size.MEDIUM)}{
        width: ${getUnitAsPixels({multiplier:1.5})};
        height: ${getUnitAsPixels({multiplier:1.5})};
        top: ${getSpacerAsPixels({multiplier:4})};
        ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:9})};
      }
    }
  
    &:nth-child(2){
      width: ${getUnitAsPixels({multiplier:1})};
      height: ${getUnitAsPixels({multiplier:1})};
      bottom: ${getUnitAsPixels({multiplier:12})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getUnitAsPixels({multiplier:5})};

      ${getMediaQuery(Size.MEDIUM)}{
        bottom: auto;
        top: 50%;
        ${({rtl}) => rtl ? `right` : `left`}: auto;
        ${({rtl}) => rtl ? `left` : `right`}: ${getSpacerAsPixels({multiplier:3})};
      }
    }
  
    &:nth-child(3){
      width: ${getUnitAsPixels({multiplier:0.5})};
      height: ${getUnitAsPixels({multiplier:0.5})};
      bottom: ${getUnitAsPixels({multiplier:13})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getUnitAsPixels({multiplier:4})};

      ${getMediaQuery(Size.MEDIUM)}{
        width: ${getUnitAsPixels({multiplier:1})};
        height: ${getUnitAsPixels({multiplier:1})};
        bottom: ${getSpacerAsPixels({multiplier:2})};
        ${({rtl}) => rtl ? `right` : `left`}: auto;
        ${({rtl}) => rtl ? `left` : `right`}: 50%;
      }
    }
  }
`

