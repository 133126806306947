import React, { useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { plainTextToHTML } from '../../../common/text';
import { Layout, Size } from '../../../theme/layout';
import { ButtonLinks } from '../../core/buttonLinks';
import { MediaButton } from '../../core/mediaButton';
import { MediaButtonIcon } from '../../core/mediaButton/mediaButton.types';
import { Overlay } from '../../core/overlay';
import { OverlayRefProps } from '../../core/overlay/overlay.types';
import { SocialLinks } from '../../core/socialLinks';
import { BlockContainer } from '../container';
import { BlockTextContent } from '../textContent';
import { Content, Heading, Text, Item, Media, MediaContainer } from './grid.styles';
import { BlockGridItemProps, BlockGridProps } from './grid.types';

const BlockGridItem = ({heading,text,socialLinks,buttonLinks,media,overlay}:BlockGridItemProps) => {
  const overlayRef = useRef<OverlayRefProps>(null);

  const handleMediaClick = () => {
    overlayRef.current?.show()
  }

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 6},
    [Size.LARGE]: {span: 6},
    [Size.XLARGE]: {span: 6},
    [Size.MAX]: {span: 6},
  };
  
  return (
    <>
      {overlay && <Overlay ref={overlayRef} {...overlay} />}
      <Item>
        <MediaContainer>
          <Media defaultSource={media} sizes={sizes} />
          {overlay && <MediaButton icon={MediaButtonIcon.PLAY} onClick={handleMediaClick} />}
        </MediaContainer>
        <BlockTextContent heading={heading} text={text} socialLinks={socialLinks} buttonLinks={buttonLinks} />
      </Item>
    </>
  )
}

export const BlockGrid = ({theme,items,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockGridProps) => {
	return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
        <Content>
          {items.map((item:BlockGridItemProps, index:number) => <BlockGridItem key={index} {...item} />)}
        </Content>
      </BlockContainer>
    </ThemeProvider>
  )
};