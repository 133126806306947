import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from '../../../theme/layout';
import { BlockContainer } from '../container/container.component';
import { BlockTextContent } from '../textContent';
import { Content } from './text.styles';
import { BlockTextProps } from './text.types';

export const BlockText = ({theme,heading,text,socialLinks,buttonLinks,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockTextProps) => {
  return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.GRID} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
        <Content>
          <BlockTextContent heading={heading} text={text} socialLinks={socialLinks} buttonLinks={buttonLinks} />
        </Content>
      </BlockContainer>
    </ThemeProvider>
  )
};