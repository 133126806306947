import React, { useState, forwardRef, useImperativeHandle, ReactNode, useEffect, MouseEvent } from 'react';
import { document } from 'browser-monads';

import { useEscButton } from '../../../hooks/useEscButton.hooks';
import { CloseButton, Container, Inner, Media } from './overlay.styles';
import { VideoPlayer } from '../videoPlayer';
import { Portal } from '../../layout/portal';
import { OverlayMediaExternalProps, OverlayMediaInternalProps, OverlayProps, OverlayRefProps } from './overlay.types';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { useIsRTL } from '../../../context/locale';


const OverlayMediaExternal = ({media}:OverlayMediaExternalProps) => {
  return <VideoPlayer {...media} autoPlay={true} />
}

const OverlayMediaInternal = ({media}:OverlayMediaInternalProps) => {
  return  <Media defaultSource={media} />
}

const Content = ({media,model}:OverlayProps) => {
  switch(model.apiKey){
    case 'overlay_media_external': return <OverlayMediaExternal media={media} />;
    case 'overlay_media_internal': return <OverlayMediaInternal media={media} />;
  }

  return null;
}

export const Overlay = forwardRef<OverlayRefProps, OverlayProps>(function ModalComponentRef(
  {model,media},
  ref
) {
  const [visible, setVisible] = useState(false);

  const themeDark = useBrandTheme("dark");
  const isRTL = useIsRTL();

  useImperativeHandle(ref, () => {
    return {
      show: () => handleShow(),
      hide: () => handleHide(),
    };
  });

  const handleShow = () => {
    setVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const handleHide = () => {
    setVisible(false);
    document.body.style.overflow = 'unset';
  };

  const handleContainerClick = (event:MouseEvent) => {
    if(event.target === event.currentTarget){
      handleHide();
    }
  }

  const handleCloseClick = (event:MouseEvent) => {
    handleHide();
  }

  useEffect(() => {
    return () => handleHide();
  }, []);

  useEscButton(() => handleHide());

  if(visible){
    return (
      <Portal>
        <ThemeProvider theme={themeDark}>
          <Container onClick={handleContainerClick}>
            <CloseButton isRTL={isRTL} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseClick}>
              <path d="M1 1L19 19" strokeWidth="2"/>
              <path d="M19 1L0.999999 19" strokeWidth="2"/>
            </CloseButton>
            <Inner mediaWidth={media.width} mediaHeight={media.height}>
              <Content media={media} model={model} />
            </Inner>
          </Container>
        </ThemeProvider>
      </Portal>
    )
  }

  return null;
});