import get from 'lodash/get';
import React from 'react';
import { Theme } from '../../../../common/types';
import { useBrandTheme } from '../../../../context/brand';
import { BlockBanner } from '../../../block/banner';
import { BlockGrid } from '../../../block/grid';
import { BlockLink } from '../../../block/link';
import { BlockMediaExternal } from '../../../block/mediaExternal';
import { BlockMediaInternal } from '../../../block/mediaInternal';
import { BlockQuote } from '../../../block/quote';
import { BlockShowsSelected } from '../../../block/showsSelected';
import { BlockText } from '../../../block/text';
import { BlockTextAndMediaInternal } from '../../../block/textAndMediaInternal';
import { BlockTitle } from '../../../block/title';
import { Container } from './block.styles';
import { BodyBlockProps } from './block.types';

const getThemeName = (block:any,fallback?:string):string | undefined => {
	const dark = useBrandTheme("dark");
	const type = getTypeName(block);

	if(type === "block_shows_selected"){
		return dark.name;
	}

	return get(block, "theme.name",fallback);
}

const getTypeName = (block:any,fallback?:string):string | undefined => {
	return get(block, "model.apiKey",fallback);
}

export const BodyBlock = ({content,showsCount,articlesCount,pageTheme,spaceBefore,spaceAfter}:BodyBlockProps) => {
  let blockBefore;
	let blockAfter;
	let blockCurrent;

	let themeCurrent;
	let themeBefore;
	let themeAfter;
	
	let extraSpaceBefore = false;
	let extraSpaceAfter = false;
	let noSpaceBefore = false;
	let noSpaceAfter = false;

	let themeChangeBefore = false;
	let themeChangeAfter = false;

	if(content){
		return (
			<Container>
				{content.map((block:any, index:number) => {
					blockBefore = content[index - 1];
					blockCurrent = content[index];
					blockAfter = content[index + 1];
					
					themeBefore = getThemeName(blockBefore,pageTheme.name);
					themeCurrent = getThemeName(blockCurrent,pageTheme.name);
					themeAfter = getThemeName(blockAfter,pageTheme.name);

					themeChangeBefore = blockBefore !== undefined && themeBefore !== themeCurrent;
					themeChangeAfter = blockAfter !== undefined && themeAfter !== themeCurrent;

					extraSpaceBefore = (themeChangeBefore || (index === 0 && spaceBefore === true));
					extraSpaceAfter = (themeChangeAfter || (index === content.length - 1 && spaceAfter === true));

					const type = getTypeName(blockCurrent);

					switch(type){
						case 'block_media_internal': return <BlockMediaInternal key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_media_external': return <BlockMediaExternal key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_text_and_media_internal': return <BlockTextAndMediaInternal key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_text': return <BlockText key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_title': return <BlockTitle key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_quote': return <BlockQuote key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_banner': return <BlockBanner key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_grid': return <BlockGrid key={index} {...block} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_shows_selected': return <BlockShowsSelected key={index} {...block} totalCount={showsCount} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
					}
					
					return null;
				})}
			</Container>
		)
	}
	return null
};