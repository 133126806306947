import styled from "styled-components";
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Heading = styled.h2`
  ${getTypography(Type.MEDIUM_HEADING)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }

  >p{
    margin-bottom: ${getSpacerAsPixels()};

    &:last-child{
      margin-bottom: 0;
    }
  }
  
`;