import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from '../../../theme/layout';
import { BlockContainer } from '../container/container.component';
import { Heading, Label } from './title.styles';
import { BlockTitleProps } from './title.types';

export const BlockTitle = ({theme,heading,label,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockTitleProps) => {
  return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.GRID} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
        {label && <Label>{label}</Label>}
        <Heading>{heading}</Heading>
      </BlockContainer>
    </ThemeProvider>
  )
};