import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { BodyBlock } from '../components/layout/body/block';
import { CoverSimple } from '../components/cover/simple';

export const query = graphql`
  query PageBlockQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageBlock(id: { eq: $id }) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      theme {
        ...Theme
      }
      title
      introduction
      coverIllustration{
        url
      }
      body {
        ...BlockMediaInternal
        ...BlockMediaExternal
        ...BlockTextAndMediaInternal
        ...BlockText
        ...BlockTitle
        ...BlockQuote
        ...BlockGrid
        ...BlockBanner
        ...BlockShowsSelected
      }
    }
    shows: allDatoCmsTvShow(sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: $locale}}) {
      totalCount
    }
    articles: allDatoCmsBlogArticle(sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: $locale}}) {
      totalCount
    }
  }
`;

const PageBlock = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const site = prop('site', data);

  const showsCount = path(['shows','totalCount'], data);
  const articlesCount = path(['articles','totalCount'], data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <CoverSimple illustration={page.coverIllustration} heading={page.title} text={page.introduction}/>
      <BodyBlock content={page.body} pageTheme={pageContext.theme} articlesCount={articlesCount} showsCount={showsCount} spaceBefore={true} spaceAfter={true} />
    </Fragment>
  );
};

export default PageBlock;