import styled from 'styled-components';
import { getGutterAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { ResponsiveMedia } from '../../core/responsiveMedia';

export const Content = styled.div`
  ${GRID}
  margin-bottom: -${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    margin-bottom: -${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`

export const Item = styled.div`
  grid-column: 1 / span 8;
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: span 6;
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};

    &:nth-child(odd){
      padding-inline-end: ${getGutterAsPixels(Size.MEDIUM)};
    }

    &:nth-child(even){
      padding-inline-start: ${getGutterAsPixels(Size.MEDIUM)};
    }
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};

    &:nth-child(odd){
      padding-inline-end: ${getGutterAsPixels(Size.LARGE)};
    }

    &:nth-child(even){
      padding-inline-start: ${getGutterAsPixels(Size.LARGE)};
    }
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};

    &:nth-child(odd){
      padding-inline-end: ${getGutterAsPixels(Size.XLARGE)};
    }

    &:nth-child(even){
      padding-inline-start: ${getGutterAsPixels(Size.XLARGE)};
    }
  }

  ${getMediaQuery(Size.MAX)}{
    margin-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};

    &:nth-child(odd){
      padding-inline-end: ${getGutterAsPixels(Size.MAX)};
    }

    &:nth-child(even){
      padding-inline-start: ${getGutterAsPixels(Size.MAX)};
    }
  }
`;

export const Heading = styled.h2`
  ${getTypography(Type.SMALL_HEADING)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  &:last-child{
    margin-bottom: 0;
  }

  >p{
    margin-bottom: ${getSpacerAsPixels()};

    &:last-child{
      margin-bottom: 0;
    }
  }
`;

export const MediaContainer = styled.div`
  margin-bottom: ${getUnitAsPixels({multiplier:4})}; 
  position: relative;
`;

export const Media = styled(ResponsiveMedia)`

`;