import styled from 'styled-components';
import { getColumns, getGutterAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import StarSVG from '../../../images/star.svg';

export const Label = styled.span`
  ${getTypography(Type.SMALL_HEADING)}
  display: flex;
  grid-column: 1 / span 4;
  align-items: center;
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  &:before{
    content: "";
    display: inline-block;
    height: 10px;
    width: ${getUnitAsPixels({multiplier:4})};
    background-color: ${({theme}) => theme.primary.hex};
    margin-inline-end: ${getUnitAsPixels()};
  }

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 8;
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels()};
    
    &:before{
      width: ${getUnitAsPixels({multiplier:8})};
      height: 15px;
    }
  }
`;

export const Heading = styled.h2`
  ${getTypography(Type.LARGE_HEADING)}
  grid-column: 1 / span 8;
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 8;
    margin-bottom: ${getSpacerAsPixels()};
  }
`;

export const Content = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 1 / span 6;
  }
`;

export const Inner = styled.div`
  ${GRID}

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getSpacerAsPixels({multiplier:2})}; 
    padding-bottom: ${getSpacerAsPixels({multiplier:2})};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_1)}
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  &:last-child{
    margin-bottom: 0;
  }

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }
`;

export const IllustrationContainer = styled.div<{rtl:boolean}>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(${getColumns(Size.SMALL)}, [col-start] 1fr);
  column-gap: ${getGutterAsPixels(Size.SMALL)};

  ${getMediaQuery(Size.MEDIUM)}{
    display: block;
    grid-column: auto;
    position: absolute;
    width: 30%;
    ${({rtl}) => rtl ? `left` : `right`}: 0;
    top: ${getSpacerAsPixels({multiplier:-2})};
    bottom: 0;
    z-index: 2;
  }
`

export const IllustrationContent = styled.div`
  grid-column: 6 / span 3;
  margin-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:-2})}};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: auto;
    margin-bottom: 0;
  }
`

export const Illustration = styled.img`
  width: 100%;
  position: relative;
  top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE,{multiplier:-1})}};
`

export const Star = styled(StarSVG)`
  position: absolute;
  fill: ${({theme}) => theme.secondary.hex};
`

export const Stars = styled.div<{rtl:boolean}>`
  display: none;
  ${getMediaQuery(Size.LARGE)}{
    display: block;
  }

  ${Star}{
    &:nth-child(1){
      width: ${getUnitAsPixels({multiplier:3})};
      top: ${getSpacerAsPixels({multiplier:2})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:4})};
    }

    &:nth-child(2){
      width: ${getUnitAsPixels({multiplier:3})};
      top: calc(50% - ${getSpacerAsPixels({multiplier:2})});
      ${({rtl}) => rtl ? `left` : `right`}: 30%;
    }

    &:nth-child(3){
      width: ${getUnitAsPixels({multiplier:3})};
      bottom: ${getSpacerAsPixels({multiplier:3})};
      ${({rtl}) => rtl ? `right` : `left`}: calc(50% + ${getSpacerAsPixels({multiplier:1})});
    }
  }
`

export const Dot = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.colors.common.light.hex};
  border-radius: 50%;
`

export const Dots = styled.div<{rtl:boolean}>`
  display: none;
  ${getMediaQuery(Size.LARGE)}{
    display: block;
  }  

  ${Dot}{
    &:nth-child(1){
      width: ${getUnitAsPixels({multiplier:1})};
      height: ${getUnitAsPixels({multiplier:1})};
      top: ${getSpacerAsPixels({multiplier:1})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:6})};
    }
  
    &:nth-child(2){
      width: ${getUnitAsPixels({multiplier:1})};
      height: ${getUnitAsPixels({multiplier:1})};
      bottom: ${getSpacerAsPixels({multiplier:2})};
      ${({rtl}) => rtl ? `right` : `left`}: ${getSpacerAsPixels({multiplier:5})};
    }
  }
`