import styled from 'styled-components';
import { getMediaQuery, getUnitAsPixels, Size } from '../../../theme/layout';
import { BlockTextAndMediaInternalVariant } from './textAndMediaInternal.types';

export const Content = styled.div<{ variant: string }>`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    display: flex;
    flex-direction: column;
    justify-content: ${({variant}) => variant === BlockTextAndMediaInternalVariant.DEFAULT || variant === BlockTextAndMediaInternalVariant.SWITCH ? `center` : `flex-start` };

    grid-row: 1;
    grid-column-start: ${({variant}) => 
      variant === BlockTextAndMediaInternalVariant.DEFAULT || variant === BlockTextAndMediaInternalVariant.EDITORIAL ? `1` : 
      variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? `9` : 
      `8`
    };
    grid-column-end: ${({variant}) => 
      variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? `span 4` : 
      `span 5`
    };
  }
`;

export const MediaContainer = styled.div<{ variant: string }>`
  grid-column: 1 / span 8;
  position: relative;
  margin-bottom: ${getUnitAsPixels({multiplier:4})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: ${({variant}) => variant === BlockTextAndMediaInternalVariant.DEFAULT || variant === BlockTextAndMediaInternalVariant.SWITCH ? `center` : `flex-start` };
    grid-row: 1;
    grid-column-start: ${({variant}) => 
      variant === BlockTextAndMediaInternalVariant.SWITCH || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? `1` : 
      variant === BlockTextAndMediaInternalVariant.EDITORIAL ? `6` : 
      `7`
    };
    grid-column-end: ${({variant}) => 
      variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? `span 7` : 
      `span 6`
    };
  }
`;

export const MediaContent = styled.div`
  position: relative;
`