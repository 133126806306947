import Color from 'color';
import styled from 'styled-components';
import { getUnitAsPixels } from '../../../theme/layout';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}) => Color(theme.colors.common.dark.hex).alpha(0.2).string()};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover{
    background-color ${({theme}) => Color(theme.colors.common.dark.hex).alpha(0.5).string()};
  }
`;

export const Icon = styled.span`
  width: ${getUnitAsPixels({multiplier:5})};
  height: ${getUnitAsPixels({multiplier:4})};
  background-color ${({theme}) => theme.colors.common.primary.hex};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg{
    fill: ${({theme}) => theme.colors.common.dark.hex};
  }
`