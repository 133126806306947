import styled from 'styled-components';
import { getMediaQuery, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Text = styled.div`
  ${getTypography(Type.LARGE_HEADING)}
  text-align: center;
  grid-column: 1 / span 8;

  &:before{
    ${getTypography(Type.HEADLINE)}
    content:'“';
    display: block;
  }

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
`;

export const Caption = styled.div`
  ${getTypography(Type.LABEL_2)}
  margin-top: ${getUnitAsPixels({multiplier:3})};
  text-align: center;
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 3 / span 8;
  }
`;