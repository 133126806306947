import React from 'react';
import { ThemeProvider } from 'styled-components';
import { CoverSimpleProps } from './simple.types';
import { Container, Content, Dot, Dots, Heading, Inner, Media, MediaContainer, Star, Stars, Text } from './simple.styles';
import { useIsRTL } from '../../../context/locale';

export const CoverSimple = ({heading,text,illustration}:CoverSimpleProps) => {
  
  const rtl = useIsRTL()

  return (
    <Container>
      
        <Stars rtl={rtl}>
          <Star />
          <Star />
          <Star />
          <Star />
        </Stars>
        <Dots rtl={rtl}>
          <Dot />
          <Dot />
          <Dot />
        </Dots>
        <Content>
          <Heading>{heading}</Heading>
          <Text>{text}</Text>
          <MediaContainer rtl={rtl}>
            <Media src={illustration.url} rtl={rtl} />
          </MediaContainer>
        </Content>
        
      
    </Container>
  )
};