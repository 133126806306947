import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { useDictionary } from '../../../context/dictionary';
import { Layout } from '../../../theme/layout';
import { ShowSlider } from '../../show/showSlider';
import { BlockContainer } from '../container/container.component';
import { BlockShowsSelectedProps } from './showsSelected.types';

export const BlockShowsSelected = ({heading,items,totalCount,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockShowsSelectedProps) => {
  const theme = useBrandTheme("dark");
  const labelTvMoreShows = useDictionary("labelTvMoreShows");
  const labelTvShowsCount = useDictionary("labelTvShowsCount");
  const subheading = `${totalCount} ${labelTvShowsCount}`;

  return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
        <ShowSlider heading={heading} subheading={subheading} items={items} link={{page:{model:{apiKey: "tv_home"}},label:labelTvMoreShows}} />
      </BlockContainer>
    </ThemeProvider>
  )
};