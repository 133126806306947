import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useIsRTL } from '../../../context/locale';
import { Layout } from '../../../theme/layout';
import { ButtonLinks } from '../../core/buttonLinks';
import { BlockContainer } from '../container/container.component';
import { Content, Dot, Dots, Heading, Illustration, IllustrationContainer, IllustrationContent, Inner, Label, Star, Stars, Text } from './banner.styles';
import { BlockBannerProps } from './banner.types';

export const BlockBanner = ({theme,heading,label,text,buttonLinks,illustration,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockBannerProps) => {
  const rtl = useIsRTL()
  
  return (
    <ThemeProvider theme={theme}>
      <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
        <Inner>
          {label && <Label>{label}</Label>}
          <Heading>{heading}</Heading>
          <Content>
            <Text>{text}</Text>
            {buttonLinks && <ButtonLinks links={buttonLinks} />}
          </Content>
        </Inner>
        {illustration && <IllustrationContainer rtl={rtl}>
          <IllustrationContent>
            <Illustration src={illustration.url} />
          </IllustrationContent>
        </IllustrationContainer>}
        <Stars rtl={rtl}>
          <Star />
          <Star />
          <Star />
        </Stars>
        <Dots rtl={rtl}>
          <Dot />
          <Dot />
        </Dots>
      </BlockContainer>
    </ThemeProvider>
  )
};