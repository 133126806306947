import styled from 'styled-components';
import { getMediaQuery, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 6;
  }
`