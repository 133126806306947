import { MouseEventHandler, ReactNode } from "react";
import { DatoModel, MediaExternal, MediaInternal } from "../../../common/types";

export enum MediaButtonIcon {
  PLAY = "PLAY",
}

export interface MediaButtonProps {
  icon: MediaButtonIcon;
  onClick?: MouseEventHandler;
}

