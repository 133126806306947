import React, { useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { plainTextToHTML } from '../../../common/text';
import { Layout, Size } from '../../../theme/layout';
import { ButtonLinks } from '../../core/buttonLinks';
import { MediaButton } from '../../core/mediaButton';
import { MediaButtonIcon } from '../../core/mediaButton/mediaButton.types';
import { Overlay } from '../../core/overlay';
import { OverlayRefProps } from '../../core/overlay/overlay.types';
import { ResponsiveMedia } from '../../core/responsiveMedia';
import { SocialLinks } from '../../core/socialLinks';
import { BlockContainer } from '../container/container.component';
import { BlockTextContent } from '../textContent';
import { MediaContainer, Content, MediaContent } from './textAndMediaInternal.styles';
import { BlockTextAndMediaInternalProps, BlockTextAndMediaInternalVariant } from './textAndMediaInternal.types';

export const BlockTextAndMediaInternal = ({theme,variant,heading,text,socialLinks,buttonLinks,media,overlay,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:BlockTextAndMediaInternalProps) => {
  const overlayRef = useRef<OverlayRefProps>(null);

  const handleMediaClick = () => {
    overlayRef.current?.show()
  }

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? 7 : 6},
    [Size.LARGE]: {span: variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? 7 : 6},
    [Size.XLARGE]: {span: variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? 7 : 6},
    [Size.MAX]: {span: variant === BlockTextAndMediaInternalVariant.EDITORIAL || variant === BlockTextAndMediaInternalVariant.EDITORIAL_SWITCH ? 7 : 6},
  };

  return (
    <>
      {overlay && <Overlay ref={overlayRef} {...overlay} />}
      <ThemeProvider theme={theme}>
        <BlockContainer layout={Layout.GRID} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
          {media && <MediaContainer variant={variant}>
            <MediaContent>
              <ResponsiveMedia defaultSource={media} sizes={sizes} onClick={handleMediaClick}  />
              {overlay && <MediaButton icon={MediaButtonIcon.PLAY} onClick={handleMediaClick} />}
            </MediaContent>
          </MediaContainer>}
          <Content variant={variant}>
            <BlockTextContent heading={heading} text={text} socialLinks={socialLinks} buttonLinks={buttonLinks} />
          </Content>
          
        </BlockContainer>
      </ThemeProvider>
    </>
  )
};