import { LinkExternal, LinkInternal, LinkSocial, MediaInternal, OverlayMediaExternal, OverlayMediaInternal, Theme } from "../../../common/types"

export enum BlockTextAndMediaInternalVariant {
  DEFAULT = "Default",
  SWITCH = "Switch",
  EDITORIAL = "Editorial",
  EDITORIAL_SWITCH = "Editorial Switch",
}

export interface BlockTextAndMediaInternalProps{
  theme: Theme;
  variant: BlockTextAndMediaInternalVariant;
  heading: string;
  text: string;
  socialLinks?: Array<LinkSocial>;
  buttonLinks?: Array<LinkExternal | LinkInternal>;
  media: MediaInternal;
  overlay?: OverlayMediaInternal | OverlayMediaExternal;
  extraSpaceBefore?:boolean;
  extraSpaceAfter?:boolean;
  noSpaceBefore?:boolean;
  noSpaceAfter?:boolean;
}