import React, { useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from '../../../theme/layout';
import { BlockContainer } from '../container/container.component';
import { Media } from './mediaExternal.styles';

export const BlockMediaExternal = ({theme,media,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter}:{theme:{name:string},variant:string,media:any,extraSpaceBefore?:boolean,extraSpaceAfter?:boolean,noSpaceBefore?:boolean,noSpaceAfter?:boolean}) => {
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter}>
          <Media {...media} />
        </BlockContainer>
      </ThemeProvider>
    </>
  )
};